/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Qanelas Soft Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Regular'), url('Radomir Tinkov  QanelasSoftRegular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Regular Italic'), url('Radomir Tinkov  QanelasSoftRegularItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Thin'), url('Radomir Tinkov  QanelasSoftThin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft UltraLight'), url('Radomir Tinkov  QanelasSoftUltraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Thin Italic'), url('Radomir Tinkov  QanelasSoftThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft UltraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft UltraLight Italic'), url('Radomir Tinkov  QanelasSoftUltraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Light';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Light'), url('Radomir Tinkov  QanelasSoftLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Light Italic'), url('Radomir Tinkov  QanelasSoftLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Medium'), url('Radomir Tinkov  QanelasSoftMedium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Medium Italic'), url('Radomir Tinkov  QanelasSoftMediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft SemiBold'), url('Radomir Tinkov  QanelasSoftSemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft SemiBold Italic'), url('Radomir Tinkov  QanelasSoftSemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Bold'), url('Radomir Tinkov  QanelasSoftBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Bold Italic'), url('Radomir Tinkov  QanelasSoftBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft ExtraBold'), url('Radomir Tinkov  QanelasSoftExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft ExtraBold Italic'), url('Radomir Tinkov  QanelasSoftExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Black';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Black'), url('Radomir Tinkov  QanelasSoftBlack.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Heavy'), url('Radomir Tinkov  QanelasSoftHeavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Black Italic'), url('Radomir Tinkov  QanelasSoftBlackItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Qanelas Soft Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Qanelas Soft Heavy Italic'), url('Radomir Tinkov  QanelasSoftHeavyItalic.woff') format('woff');
    }